import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { CropSubType } from '@crop-nutrient/model/crop-sub-type';
import { CropType } from '@crop-nutrient/model/crop-type';
import { CropYieldAverage } from '@crop-nutrient/model/crop-yield-average';
import { NitrogenCredit } from '@crop-nutrient/model/nitrogen-credit';
import { SoilTexture } from '@crop-nutrient/model/soil-texture';
import { CropRotation } from '@fmp/model/crop-rotation.model';
import { SoilTestCode } from '@shared/models/worksheet/soil-test-code.model';
import { EMPTY, Observable, of } from 'rxjs';
import { SoilSerie } from '../models/crop/soil-serie';
import { TillageMethod } from '../models/crop/tillage-method';
import { TillagePractice } from '../models/crop/tillage-practice';

@Injectable({
  providedIn: 'root'
})
export class CropLookupService {
  constructor(private httpClient: HttpClient) {}

  allTillageMethods() {
    return this.httpClient.get(URLs.TILLAGE_METHODS_ENDPOINT) as Observable<TillageMethod[]>;
  }

  allTillagePractices() {
    return this.httpClient.get(URLs.TILLAGE_PRACTICES_ENDPOINT) as Observable<TillagePractice[]>;
  }

  allSoilTextures() {
    return this.httpClient.get(URLs.ALL_SOIL_TEXTURES_ENDPOINT) as Observable<SoilTexture[]>;
  }

  soilTexture(id: number) {
    if (id) {
      const params = new HttpParams().set('id', id.toString());
      return this.httpClient.get(URLs.SOIL_TEXTURES_ENDPOINT, { params }) as Observable<SoilTexture>;
    }
    return EMPTY;
  }

  soilTestCodes() {
    return this.httpClient.get(URLs.SOIL_TEST_CODE_ENDPOINT) as Observable<SoilTestCode[]>;
  }

  getAllCropTypeOptions() {
    return this.httpClient.get(URLs.CROP_TYPES_ENDPOINT) as Observable<CropType[]>;
  }

  getAllCropSubTypeOptions() {
    return this.httpClient.get(URLs.CROP_SUB_TYPES_ENDPOINT) as Observable<CropSubType[]>;
  }

  getAllNitrogenCreditOptions() {
    return this.httpClient.get(URLs.CROP_NITROGEN_CREDITS_ENDPOINT) as Observable<NitrogenCredit[]>;
  }

  cropRotations() {
    return this.httpClient.get(URLs.CROP_ROTATIONS_ENDPOINT) as Observable<CropRotation[]>;
  }

  soilSeries(upperTierCode: number) {
    const params = new HttpParams()
      .set('all-if-none-found', 'true')
      .set('upper-tier-code', !!upperTierCode ? upperTierCode.toString() : '0');
    return this.httpClient.get(URLs.SOIL_SERIES_ENDPOINT, { params }) as Observable<SoilSerie[]>;
  }

  soilTextures(soilSeriesId: number, upperTierCode: number) {
    if (!upperTierCode) {
      return of([]);
    }
    const params = new HttpParams().set('soil-series-id', soilSeriesId.toString()).set('upper-tier-code', upperTierCode.toString());
    return this.httpClient.get(URLs.SOIL_TEXTURES_ENDPOINT, { params }) as Observable<SoilTexture[]>;
  }

  soilSerie(id: number) {
    if (id) {
      return this.httpClient.get(URLs.SOIL_SERIES_ENDPOINT + '/' + id) as Observable<SoilSerie>;
    }
    return EMPTY;
  }

  cropSubType(id: number) {
    if (id) {
      return this.httpClient.get(URLs.CROP_SUB_TYPES_ENDPOINT + '/' + id) as Observable<CropSubType>;
    }
    return EMPTY;
  }

  cropType(id: number) {
    if (id) {
      return this.httpClient.get(URLs.CROP_TYPES_ENDPOINT + '/' + id) as Observable<CropType>;
    }
    return EMPTY;
  }

  cropYieldAverage(cropId: number, geoTownShipName: string, upperTierCode: number): Observable<CropYieldAverage> {
    const url = `${URLs.CROP_YIELD_AVERAGE_ENDPOINT}?crop-id=${cropId}&geotownship=${geoTownShipName}&upper-tier-code=${upperTierCode}`;
    return this.httpClient.get(url) as Observable<CropYieldAverage>;
  }
}
