import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { URLs } from '../../core/conf/urls';
import { GeoTownship } from '../models/location/geo-township';
import { LowerTierMunicipality } from '../models/location/lower-tier-municipality';
import { UpperTierMunicipality } from '../models/location/upper-tier-municipality';

@Injectable({ providedIn: 'root' })

export class LocationService {
  constructor(private httpClient: HttpClient) { }

  public upperTierMunicipalities() {
    return this.httpClient.get(URLs.UPPER_TIER_MUNIS_ENDPOINT) as Observable<UpperTierMunicipality[]>;
  }

  public lowerTierMunicipalities() {
    return this.httpClient.get(URLs.LOWER_TIER_MUNIS_ENDPOINT) as Observable<LowerTierMunicipality[]>;
  }

  public geoTownships(upperTierCode: number, lowerTierCode?: number) {
    const url = !!lowerTierCode ? `${URLs.GEOTOWNSHIPS_ENDPOINT}?upper-tier-code=${upperTierCode}&lower-tier-code=${lowerTierCode}`
      : `${URLs.GEOTOWNSHIPS_ENDPOINT}?upper-tier-code=${upperTierCode}`;
    return upperTierCode === null || upperTierCode === 0
      ? EMPTY : this.httpClient.get(url) as Observable<GeoTownship[]>;
  }

  public geoTownship(id: string) {
    if (id) {
      const url = `${URLs.GEOTOWNSHIPS_ENDPOINT}/${id}`;
      return this.httpClient.get(url) as Observable<GeoTownship>;
    }
    return EMPTY;
  }

  public allGeoTownships() {
    const url = `${URLs.GEOTOWNSHIPS_ENDPOINT}?unique-names=false`;
    return this.httpClient.get(url) as Observable<GeoTownship[]>;
  }

  public cropHeatUnit(upperTierCode: number, geoTownshipName: string): Observable<number> {
    if (!upperTierCode || upperTierCode === 0) {
      return of(3000);
    } else {
      const url = geoTownshipName
        ? `${URLs.CROP_HEAT_UNITS_ENDPOINT}?geotownship=${geoTownshipName}&upper-tier-code=${upperTierCode}`
        : `${URLs.CROP_HEAT_UNITS_ENDPOINT}?upper-tier-code=${upperTierCode}`;
      return this.httpClient.get(url) as Observable<number>;
    }
  }
}
